/*********************************
	共通
*********************************/
html, body, div {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  color: #444444;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
div, nav, section {
  box-sizing: border-box;
}
ol, ul, li, p {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
img {
  user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -webkit-touch-callout: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
a {
  text-decoration: none;
  transition: .5s;
}
p, a {
  color: #444444;
  word-break: normal;
}
.font-mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
.pc {
  display: block;
}
.sp {
  display: none;
}
.sp-only {
  display: none;
}
.tablet {
  display: none;
}

/* ページ共通コンテンツ */
.content-block {
  padding: 100px 0px;
}
.content-block-title-block {
  margin-bottom: 80px;
}
.content-block-title-block p.en-title {
  font-size: 24px;
  font-family: dolce, sans-serif;
}
.content-block-title-block p.content-block-title-sub {
  font-size: 18px;
  margin-top: 24px;
}
.content-block-title-block h2 {
  font-size: 36px;
}
.content-block-title-block h3 {
  font-size: 32px;
}
.content-block-title-block h2 span {
  font-size: 60px;
}


/*********************************
	coming soon
*********************************/
.comingsoon-box {
  width: 800px;
  background: -moz-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: -webkit-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: linear-gradient(to bottom right, #EDFAF8, #FFF7F4);
  margin-top: 50px;
  padding: 50px 0px;
}
.about-card-comingsoon {
  padding: 40px 0px;
}


/*********************************
	ヘッダー
*********************************/
header {
  background-color: rgba(255,255,255,.9);
  width: 100%;
  position: fixed;
  z-index: 9999;
  height: 85px;
  top: 0;
  left: 0px;
}
header a.header-link {
  width: 240px;
  margin-top: 12px;
}
header a.btn {
  background: -moz-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: -webkit-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: linear-gradient(to bottom right, #EDFAF8, #FFF7F4);
  border: 1px solid #444444;
  border-radius: 40px;
  color: #444444;
  padding: 12px 0px;
  width: 300px;
  display: block;
  position: absolute;
  right: 12px;
  top: 16px;
}
header a.to-free-regist {
  position: absolute;
  left: 24px;
  line-height: 22px;
  height: 14px;
  top: 50%;
  margin-top: -7px;
}

/*********************************
	メインビジュアル
*********************************/
.main-visual {
  padding-top: 85px;
}
.main-visual:before {
  content: "";
  background: -moz-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: -webkit-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: linear-gradient(to bottom right, #EDFAF8, #FFF7F4);
  width: 80vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
.main-visual-inner {
  max-width: 1250px;
  padding: 80px 0px;
  z-index: 1000;
}
.main-visual-subtitle {
  margin-top: 32px;
  font-size: 15px;
}
.main-visual .img-block {
  height: 550px;
}
.main-visual .img-block .main-visual-sub {
  width: 180px;
  right: -40px;
  bottom: 80px;
}
.main-visual .img-block .main-visual-deco {
  width: 200px;
  left: -40px;
  top: -20px;
}
.main-visual .text-block {
  padding-left: 8vw;
}
.topic-list {
  margin-top: 40px;
}
.topic-list ul {
  padding: 10px 0px;
  border-left: 1px solid #707070;
  margin-left: 12px;
}
.topic-list li {
  padding: 6px 0px 6px 20px;
}
.topic-list li a {
  font-size: 15px;
}


/*********************************
	オフィシャルメンバーの説明
*********************************/
.official-member-text {
  margin-top: 100px;
  padding: 50px 100px;
  border-left: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  background: #fbfbfb;
}
.official-member-text-inner {
  font-size: 16px;
}
.official-member-text-title {
  font-size: 26px;
  margin-bottom: 32px;
  color: #77cbc0;
}
.official-member-text-subtext {
  margin-top: 24px;
  font-size: 13px;
  color: gray;
}


/*********************************
	オフィシャルメンバーの7つのたのしみ♪
*********************************/
.fun-list-el {
  margin-bottom: 24px;
}
.fun-list-el .text-block {
  margin-top: 32px;
  padding-left: 40px;
}
.fun-list-el:nth-child(even) .text-block {
  padding-left: 20px;
  padding-right: 30px;
}
.fun-list-el .text-block .fun-list-el-count {
  font-size: 80px;
  font-family: dolce, sans-serif;
  color: #77cbc0;
  line-height: 1;
}
.fun-list-el .text-block .fun-list-el-count span {
  font-size: 32px;
}
.fun-list-el .text-block .fun-list-el-text {
  font-size: 26px;
}
.fun-list-el .text-block .fun-list-el-description {
  font-size: 15px;
  line-height: 1.8;
  margin-top: 16px;
}
.fun-list-el-description span {
  border-bottom: 3px solid #ffef00;
}


/*********************************
	会員費について
*********************************/
.membership-block {
  background-color: #FAF9EC;
}
.membership-block .membership-block-inner {
  width: 920px;
}
.fee-block {
  background-color: #fff;
  padding: 40px 0px;
}
.fee-block p {
  font-size: 22px;
  line-height: 1.8;
}
.fee-block p.small-sub-text {
  font-size: 15px;
  margin-top: 12px;
}
.fee-block p span.font-large {
  font-size: 42px;
}
.fee-block p span.border-bottom-main {
  border-bottom: 2px solid #8DDCD1;
  padding-bottom: 4px;
}
.membership-block table {
  margin-top: 50px;
  margin-bottom: 40px;
}
.membership-block table tr th {
  font-size: 18px;
  text-align: right;
  padding-right: 20px;
  vertical-align: middle;
}
.membership-block table tr td {
  border: 10px solid #FAF9EC;
  text-align: center;
  padding: 16px;
}
.membership-block table tr td:nth-child(2) {
  background-color: #fff;
}
.membership-block table tr td:nth-child(3) {
  background-color: #B3E2DB;
}
.membership-block table tr td .table-member {
  font-size: 24px;
  line-height: 36px;
  height: 72px;
  margin-bottom: 24px;
}
.membership-block table tr td .table-fee {
  font-size: 16px;
}
.membership-block table tr td .table-fee span {
  font-size: 32px;
}
.membership-block table tr td img {
  width: 25px;
}
.membership-slide-area {
  margin: 80px 0px 120px;
}
.membership-slide-area ul {
  height: 450px;
}
.membership-slide-area:after {
  content: "";
  height: 560px;
  width: 1px;
  background-color: #707070;
  top: 0;
  position: absolute;
  left: 24px;
}
.membership-slide-area-title {
  font-size: 24px;
  margin-bottom: 16px;
  padding-left: 45px;
}
.membership-fukidashi {
  width: 300px;
  right: 50px;
  top: -60px;
}
.membership-fukidashi.fukidashi02 {
  width: 280px;
  right: 150px;
  top: -40px;
}
.membership-slide-area .slick-list,
.membership-slide-area .slick-track {
  height: 100%;
}
.membership-slide-area .slick-list {
  overflow-x: clip;
  overflow-y: visible;
}
.slick-next {
  right: 35px;
  top: auto;
  bottom: 5px;
}
.slick-next:before {
  content: url(../../assets/img/membership-slide-arrow.svg)!important;
  opacity: 1!important;
}
.about-card-imgblock {
  padding-right: 50px!important;
}
.card-description {
  border: 1px solid #B4B4B4;
  padding: 2px;
  background-color: #fff;
}
.card-description-inner {
  border: 2px solid #B4B4B4;
  padding: 32px;
}
.card-description-inner .invitation-title {
  width: 100%;
  margin-bottom: 16px;
}
.card-description-inner p.text-title {
  border-bottom: 3px solid #77cbc0;
}
.card-description-inner p {
  font-size: 22px;
  margin-bottom: 20px;
}
.card-description-inner p.text-description {
  font-size: 14px;
  line-height: 1.5;
  text-align: justify
}
.card-description-inner p.story-description {
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 0px;
}
.card-description-inner p.story-description span {
  font-size: 18px;
  display: block;
  margin-bottom: 8px;
}
.card-description-inner ul li {
  margin-bottom: 10px;
}
.card-description-inner ul li:last-child {
  margin-bottom: 0px;
}
.application-list-block {
  margin-top: 100px;
}
.application-list {
  margin-top: 32px;
}
.application-list ul {
  width: 650px;
}
.application-list ul li {
  margin-bottom: 50px;
}
.application-list ul li:last-child {
  margin-bottom: 0px;
}
.application-list-text-block {
  padding-left: 24px;
}
.application-list-maintext {
  font-size: 24px;
  margin-bottom: 6px;
}
.application-list-descriptiontext {
  font-size: 16px;
}
.application-list-descriptiontext-sub {
  font-size: 14px;
  margin-top: 12px;
}
.application-list-block .withdrawal-text {
  font-size: 14px;
  margin-top: 50px;
}
.announce-membershipcard {
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 16px;
}
.announce-membershipcard a {
  text-decoration: underline;
}


/*********************************
	お問い合わせフォーム
*********************************/
.input-container {
  width: 800px;
}
.form-control {
  font-size: 14px;
  color: #444444;
}
.input-container label {
  font-size: 20px;
}
.input-container label.error {
  color: red;
}
.input-container label span {
  color: #8DDCD1;
}
.input-container label span.any-area {
  background-color: #b4d4ef;
  font-size: 16px;
  padding: 4px;
  margin-left: 10px;
  color: #fff;
}
.input-container input {
  background-color: #F4F4F4;
  border: none;
  border-radius: 0px;
  height: 50px;
}
.input-container select {
  background-color: #F4F4F4;
  border: none;
  border-radius: 0px;
  height: 50px;
}
.input-container .birthday select {
  margin-right: 12px;
  width: 100px;
}
.input-container textarea {
  background-color: #F4F4F4;
  border: none;
  border-radius: 0px;
  height: 250px;
}
.form-el {
  margin-bottom: 32px;
}
.form-el p {
  margin-bottom: 12px;
}

/* Kinjo Official Member’s Cardの選択部分 */
.form-card-select p {
  margin-bottom: 0px;
}
.form-card-select .card-select-img {
  height: 100px;
}
.form-card-select label span {
  color: #444444;
}
.btn1_wrap{
  height:50px;
  position:relative;
}
.btn1_wrap input{
  opacity: 0;
}
.btn1_wrap label{
  width:80px;
  height:40px;
  box-sizing:border-box;
  float:left;
  border-radius:100px;
  position:relative;
  cursor:pointer;
  transition:.3s ease;
  box-shadow: 0 0 4px rgba(0,0,0,.3);
}
.btn1_wrap input:focus + label,
.btn1_wrap input:focus + label:before{
  box-shadow: 0 0 4px #34abff;
}
.btn1_wrap input:checked + label{
  background:#8ddcd1;
}
.btn1_wrap label:before{
  content:'';
  width:30px;
  height:30px;
  position:absolute;
  background:white;
  left:5px;
  top:5px;
  box-sizing:border-box;
  color:black;
  border-radius:100px;
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  transition:.3s;
}
.btn1_wrap input[type=checkbox]:checked + label:before{
  left:43px;
}
.btn1_wrap label span{
  width: 200px;
  position: absolute;
  left: calc(100% + 15px);
  font-size: 20px;
  line-height: 40px;
}

.payment-information {
  padding: 24px;
  border: 1px solid gray;
  margin-top: 50px;
}
.payment-information .form-el {
  margin-bottom: 20px;
}
.payment-information .form-el:last-child {
  margin-bottom: 10px;
}
.payment-information-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.payment-information-list {
  margin-bottom: 20px;
}
.payment-information-list ul {
  display: inline-block;
}
.payment-information-list li {
  padding: 0px 10px;
  display: inline-block;
}
.payment-information-list li img.img-visa {
  width: 70px;
}
.payment-information-list li img.img-master {
  width: 60px;
}
.payment-information-list li img.img-jcb {
  width: 60px;
}
.payment-information-list li img.img-ae {
  width: 50px;
}
.payment-information-list li img.img-dc {
  width: 60px;
}
.payment-information-list li img.img-dcv {
  width: 68px;
}
.input-container .btn {
  background: -moz-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: -webkit-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: linear-gradient(to bottom right, #EDFAF8, #FFF7F4);
  border: 1px solid #444444;
  border-radius: 40px;
  color: #444444;
  padding: 16px 0px;
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-top: 16px;
}
.ElementsApp, .ElementsApp .InputElement {
  padding: 12px;
}


/*********************************
	メッセージブロック
*********************************/
.message-block {
  margin-bottom: 100px;
}
.message-block img {
  width: 300px;
}
.message-block p {
  font-size: 18px;
  margin-top: 32px;
}


/*********************************
	サンクスページ
*********************************/
.thanks-block {
  margin: 85px 0px;
  padding: 100px 0px;
  background: -moz-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: -webkit-linear-gradient(top left, #EDFAF8, #FFF7F4);
  background: linear-gradient(to bottom right, #EDFAF8, #FFF7F4);
}
.thanks-main-title {
  font-size: 32px;
}
.thanks-main-text {
  font-size: 18px;
  margin-top: 32px;
}


/*********************************
	フッター
*********************************/
footer {
  position: relative;
  padding: 150px 0px 50px;
  background: url(../../assets/img/footer-bg.jpg) right center / cover no-repeat;
}
footer:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,.8);
  z-index: 999;
}
footer .footer-inner {
  position: relative;
  z-index: 1000;
}
footer .top-link {
  width: 350px;
  background-color: rgba(255,255,255,.9);
  font-size: 16px;
  padding: 12px 0px;
  margin-bottom: 80px;
}
.footer-list {
  width: 1050px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
}
.footer-list li {
  display: inline-block;
  padding: 6px 12px;
}
.footer-list li a {
  font-size: 14px;
}


/*********************************
	スマートフォン
*********************************/
@media screen and (max-width: 1024px) {


  /*********************************
      共通
  *********************************/
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
  .sp-only {
    display: block;
  }
  .tablet {
    display: none;
  }
  .content-block {
    padding: 60px 0px;
  }
  .content-block-title-block h2 {
    font-size: 22px;
  }
  .content-block h3 {
    font-size: 22px;
  }
  .content-block-title-block p.en-title {
    font-size: 20px;
  }
  .content-block-title-block h2 span {
    font-size: 40px;
  }
  .content-block-title-block {
    margin-bottom: 40px;
  }
  .content-block-title-block p.content-block-title-sub {
    font-size: 13px;
    margin-top: 20px;
  }


  /*********************************
      coming soon
  *********************************/
  .comingsoon-box {
    width: 92%;
    margin-top: 32px;
    padding: 24px 0px;
  }
  .comingsoon-box img {
    width: 120px;
  }
  .about-card-comingsoon {
    padding: 12px 0px;
    width: 130px;
  }


  /*********************************
      ヘッダー
  *********************************/
  header {
    height: 50px;
  }
  header a.btn {
    display: none;
  }
  header a.header-link {
    width: 140px;
    margin-top: 8px;
  }
  header a.to-free-regist {
    width: 76px;
    position: absolute;
    left: 6px;
    line-height: auto;
    height: auto;
    top: 14px;
    margin-top: 0px;
  }


  /*********************************
      メインビジュアル
  *********************************/
  .main-visual {
    padding-top: 50px;
  }
  .main-visual-inner {
    width: 100%;
    padding: 40px 0px 0px;
  }
  .main-visual:before {
    width: 100vw;
    height: 95%;
  }
  .main-visual .img-block {
    height: 420px;
  }
  .main-visual .img-block .main-visual-sub {
    width: 120px;
    right: -40px;
    bottom: 80px;
  }
  .main-visual .img-block .main-visual-deco {
    width: 120px;
    left: auto;
    bottom: auto;
    top: -10px;
    right: -40px;
  }
  .main-visual .text-block {
    padding: 0px 12vw;
  }
  .main-visual-subtitle {
    font-size: 13px;
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
  }


  /*********************************
      オフィシャルメンバーの説明
  *********************************/
  .official-member-text {
    margin-top: 50px;
    width: 92%;
    padding: 24px;
  }
  .official-member-text-inner {
    font-size: 12px;
  }
  .official-member-text-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .official-member-text-subtext {
    margin-top: 20px;
    font-size: 10px;
  }


  /*********************************
      オフィシャルメンバーの7つのたのしみ♪
  *********************************/
  .fun-block {
    width: 92%;
    margin: 0 auto;
  }
  .fun-list-el {
    margin-bottom: 32px;
  }
  .fun-list-el:last-child {
    margin-bottom: 0px;
  }
  .fun-list-el > div.row {
    align-items: center;
  }
  .fun-list-el .text-block {
    padding-left: 12px;
    margin-top: 0px;
  }
  .fun-list-el .text-block .fun-list-el-count {
    font-size: 60px;
    line-height: 0.6;
    margin-bottom: 6px;
  }
  .fun-list-el .text-block .fun-list-el-count span {
    font-size: 24px;
  }
  .fun-list-el .text-block .fun-list-el-text {
    font-size: 16px;
  }
  .fun-list-el:nth-child(even) .text-block {
    padding-left: 20px;
    padding-right: 0px;
  }
  .fun-list-el .fun-list-el-description-block {
    width: 92%;
    background-color: #f9f9f9;
    margin-top: 16px;
  }
  .fun-list-el .fun-list-el-description {
    font-size: 12px;
    line-height: 1.6;
    padding: 10px;
  }
  .fun-list-el-description span {
    border-bottom: 2px solid #ffef00;
  }


  /*********************************
      会員費について
  *********************************/
  .membership-block {
    padding: 60px 0px;
  }
  .membership-block .membership-block-inner {
    width: 100%;
  }
  .fee-block {
    padding: 20px 0px;
    width: 92%;
    margin: 0 auto;
  }
  .fee-block p {
    font-size: 16px;
  }
  .fee-block p.small-sub-text {
    font-size: 12px;
  }
  .fee-block p span.font-large {
    font-size: 36px;
  }
  .membership-block table {
    width: 92%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 24px;
  }
  .membership-block table tr {
    padding-bottom: 8px;
  }
  .membership-block table tr th {
    padding: 0px 12px 0px 0px;
    font-size: 11px;
  }
  .membership-block table tr td {
    padding: 6px;
    border: 5px solid #FAF9EC;
  }
  .membership-block table tr td .table-member {
    font-size: 13px;
    line-height: 18px;
    height: 54px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .membership-block table tr td .table-fee {
    font-size: 12px;
  }
  .membership-block table tr td .table-fee span {
    font-size: 20px;
  }
  .membership-block table tr td img {
    width: 20px;
  }
  .membership-block table tr td p {
    font-size: 12px;
  }
  .membership-slide-area-title {
    font-size: 14px;
    margin-bottom: 12px;
    padding-left: 28px;
  }
  .membership-fukidashi {
    width: 150px;
    right: 2px;
    top: -40px;
  }
  .membership-fukidashi.fukidashi02 {
    width: 160px;
    right: 30px;
    top: -30px;
  }
  .membership-slide-area {
    margin: 50px 0px 60px;
  }
  .membership-slide-area ul {
    height: 200px;
  }
  .membership-slide-area:after {
    height: 270px;
    left: 12px;
    width: 1px;
  }
  .about-card-block {
    width: 92%;
    margin: 0 auto;
  }
  .card-description-inner {
    padding: 24px;
  }
  .card-description-inner .invitation-title {
    width: 80%;
  }
  .card-description-inner p {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .card-description-inner p.text-description {
    font-size: 12px;
  }
  .card-description-inner p.story-description {
    font-size: 12px;
  }
  .card-description-inner p.story-description span {
    font-size: 18px;
    display: block;
    margin-bottom: 8px;
  }
  .card-description-inner ul li {
    font-size: 12px;
  }
  .about-card-imgblock {
    padding-right: 0px!important;
    height: 200px;
    margin-bottom: 50px;
  }
  .application-list-block {
    width: 92%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .application-list-text {
    font-size: 15px;
    padding-left: 8px;
  }
  .application-list ul {
    width: 100%;
  }
  .application-list ul li {
    margin-bottom: 20px;
  }
  .application-list-text-block {
    padding-left: 12px;
  }
  .application-list-maintext {
    font-size: 18px;
    margin-bottom: 4px;
  }
  .application-list-descriptiontext {
    font-size: 12px;
  }
  .application-list-descriptiontext-sub {
    font-size: 11px;
    margin-top: 8px;
  }
  .application-list-block .withdrawal-text {
    font-size: 11px;
    margin-top: 24px;
  }
  .announce-membershipcard {
    font-size: 11px;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 8px;
  }


  /*********************************
      お問い合わせフォーム
  *********************************/
  .form-control {
    font-size: 12px;
  }
  .input-container {
    width: 92%;
  }
  .input-container label {
    font-size: 16px;
  }
  .input-container label span.any-area {
    font-size: 12px;
  }
  .input-container input {
    height: 40px;
  }
  .form-el {
    margin-bottom: 20px;
  }
  .payment-information {
    padding: 12px;
    margin-top: 40px;
  }
  .input-container .btn {
    width: 100%;
    font-size: 15px;
    padding: 12px 0px;
  }
  .payment-information-list ul {
    justify-content: center;
  }
  .payment-information-list li {
    padding: 8px;
  }
  .payment-information-list li img.img-visa {
    width: 55px;
  }
  .payment-information-list li img.img-master {
    width: 45px;
  }
  .payment-information-list li img.img-jcb {
    width: 50px;
  }
  .payment-information-list li img.img-ae {
    width: 40px;
  }
  .payment-information-list li img.img-dc {
    width: 50px;
  }
  .payment-information-list li img.img-dcv {
    width: 58px;
  }
  .form-el p {
    font-size: 12px;
  }
  .input-container .birthday select {
    font-size: 12px;
    color: #444444;
  }
  .btn1_wrap {
    height: 40px;
  }
  .btn1_wrap label {
    width: 60px;
    height: 30px;
  }
  .btn1_wrap label:before {
    width: 20px;
    height: 20px;
  }
  .btn1_wrap input[type=checkbox]:checked + label:before {
    left: 34px;
  }
  .btn1_wrap label span {
    width: 100px;
    font-size: 16px;
    line-height: 30px;
  }
  .form-card-select .card-select-img {
    height: 80px;
  }


  /*********************************
      メッセージブロック
  *********************************/
  .message-block {
    margin-bottom: 80px;
  }
  .message-block img {
    width: 200px;
  }
  .message-block p {
    font-size: 16px;
    margin-top: 24px;
  }


  /*********************************
      サンクスページ
  *********************************/
  .thanks-block {
    margin: 50px 0px;
    padding: 60px 0px;
  }
  .thanks-main-title {
    font-size: 22px;
  }
  .thanks-main-text {
    font-size: 13px;
    margin-top: 32px;
  }


  /*********************************
      フッター
  *********************************/
  footer {
    padding: 80px 0px 20px;
    background: url(../../assets/img/footer-bg.jpg) center center/cover no-repeat;
  }
  footer:after {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .footer-list {
    width: 92%;
    margin-top: 80px;
    margin-bottom: 32px;
  }
  .footer-list li {
    padding: 4px 6px;
  }
  .footer-list li a {
    font-size: 12px;
  }
  footer .kinjo-logo img {
    width: 80px;
  }
  footer .top-link {
    width: 92%;
    font-size: 13px;
    padding: 10px 0px;
    margin-bottom: 32px;
  }
  footer .copyright {
    font-size: 11px;
  }


}



/*********************************
	タブレット
*********************************/
@media screen and (min-width: 481px) and (max-width:1024px) {

  .content-block-title-block h2 {
    font-size: 28px;
  }
  .content-block-title-block p.en-title {
    font-size: 24px;
    margin-bottom: 12px;
  }


  /*********************************
      coming soon
  *********************************/
  .comingsoon-box {
    width: 80%;
  }


  /*********************************
      メインビジュアル
  *********************************/
  .main-visual .img-block {
    height: 450px;
  }
  .main-visual .text-block {
    padding-left: 6vw;
    padding-right: 4vw;
  }
  .main-visual-subtitle {
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .topic-list {
    margin-top: 30px;
  }
  .topic-list li {
    padding: 4px 0px 4px 20px;
  }


  /*********************************
      オフィシャルメンバーの説明
  *********************************/
  .official-member-text {
    width: 72%;
    padding: 40px;
  }
  .official-member-text-inner {
    font-size: 12px;
  }
  .official-member-text-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .official-member-text-subtext {
    margin-top: 20px;
    font-size: 10px;
  }


  /*********************************
      オフィシャルメンバーの7つのたのしみ♪
  *********************************/
  .fun-list-el .text-block .fun-list-el-count {
    font-size: 70px;
  }
  .fun-list-el .text-block .fun-list-el-text {
    font-size: 18px;
  }
  .fun-list-el .fun-list-el-description {
    padding: 20px;
  }


  /*********************************
      プランについて
  *********************************/
  .fee-block {
    width: 80%;
  }
  .membership-block table {
    width: 80%;
  }
  .membership-slide-area {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 80px;
  }
  .membership-slide-area ul {
    height: 350px;
  }
  .membership-slide-area-title {
    font-size: 18px;
    margin-bottom: 20px;
    padding-left: 40px;
  }
  .membership-slide-area:after {
    height: 450px;
    left: 20px;
  }
  .about-card-block {
    width: 80%;
  }
  .application-list-block {
    width: 80%;
  }
  .payment-information {
    padding: 24px;
  }
  .payment-information-list ul {
    justify-content: start;
  }
  .about-card-imgblock {
    height: 300px;
  }


}


/*********************************
	PCのみ
*********************************/
@media screen and (min-width:1025px) {
  a {
    transition: .5s;
  }
  a:hover {
    opacity: .5;
    text-decoration: none;
    color: #444;
  }



}
