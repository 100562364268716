#loading {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0,0,0,0.3);
  z-index: 1;
}
#spinner {
  display: none;
  color: #fff;
  z-index: 2;
}
.circle{
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #B3E2DB;
  text-align:center;
  line-height: 30px;
  padding-right: 2px;
  color: #fff;
  font-size: 16px;
}
.msf-view {
  display: none;
}
.msf-navigation {
  text-align: center;
}
.msf-nav-button {
  display: none;
}
.msf-header {
  padding-top: 10px;
  margin-bottom: 40px;
  color: #777;
}
.msf-header .msf-step {
  font-size: 15px;
  margin-bottom: 10px;
}
.msf-header .msf-step:hover {
  cursor: pointer;
}
.msf-header .msf-step i.fa {
  height: 60px;
  width: 60px;
  line-height: 55px;
  text-align: center;
  border: 3px solid #777;
  border-radius: 100%;
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.msf-header .msf-step.msf-step-complete {
  /*color: #ef4035;*/
  color: #77cbc0;
  font-weight: bold;
  opacity: .5;
}
.msf-header .msf-step.msf-step-complete i.fa {
  /*border-color : #ef4035;*/
  border-color: green;
}
.msf-header .msf-step.msf-step-incomplete {
  /*color: #ef4035;*/
  color:red;
}
.msf-header .msf-step.msf-step-incomplete i.fa {
  /*border-color : #ef4035;*/
  border-color: red;
}
.msf-header .msf-step.msf-step-active,
.msf-header .msf-step.msf-step-active.msf-step-complete,
.msf-header .msf-step.msf-step-active.msf-step-incomplete {
  color: #77cbc0;
  font-weight: bold;
}
.msf-header .msf-step.msf-step-active i.fa,
.msf-header .msf-step.msf-step-active.msf-step-complete i.fa,
.msf-header .msf-step.msf-step-active.msf-step-incomplete i.fa  {
  border-color: blue;
  /*border-color : #3c763d;*/
}
.input-validation-error {
  border-color: red;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
}

.input-validation-error:focus {
  border-color: red;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
}

.msf-navigation .btn {
  display: inline;
}
